import React from 'react';
import './App.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

// Site Imports
import { Home } from './pages/site/Home';

const App: React.FC = () => (
    <Router>
        <Switch>
            <Route exact path="/page2">
                <div>
                    Page 2
                </div>
            </Route>
            <Route exact path="/">
                <Home />
            </Route>
        </Switch>
    </Router>
);

export default App;
