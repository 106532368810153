import React from 'react';
import logo from '../../assets/branding/logo.svg';

import placeholder from '../../assets/img/placeholder.jpg';
import glow from '../../assets/glow.png';

export const Home: React.FC = () => (
    <div className="bg-black">
        {/* Placeholder images */}
        <img className="absolute opacity-3 w-screen h-screen" src={glow} alt=""/>
        <img className="absolute opacity-5 w-screen h-screen object-cover" src={placeholder} alt=""/>
        <div className="relative h-screen w-screen">
            <div className="absolute w-full h-1/2 top-1/4 px-3 sm:px-8 text-center space-y-5">
                <img className="h-3/4 m-auto" src={logo} alt=""/>
                <h1 className="text-pink-400 text-3xl sm:text-5xl font-semibold">Construction in Progress</h1>
                <p className="text-white text-xl sm:text-2xl max-w-prose mx-auto">Our airports are under construction! Check back later for our great exotic destinations in the world of flight simulation.</p>
            </div>
        </div>
    </div>
);
